import React, { useCallback, useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';

const MultiCardCarousel = ({
    children,
    autoScrollInterval = 3000,
    theme = 'light',
    infiniteScroll = false,
    dense = false,
    showOverflow = {
        right: false,
        left: false,
    },
    controls = {
        show: 'VISIBLE',
        placement: 'DEFAULT',
    },
}: {
    children: React.ReactElement;
    /**
     * Set to null if you don't want it to scroll automatically
     */
    autoScrollInterval?: number | null;
    theme?: 'light' | 'dark';
    infiniteScroll?: boolean;
    dense?: boolean;
    showOverflow?: {
        right?: boolean;
        left?: boolean;
    };
    controls?: {
        /**
         * Even if controls.show is set to 'VISIBLE', the controls will still be hidden if there are less items than visible cards
         */
        show?: 'VISIBLE' | 'HIDDEN';
        placement?: 'DEFAULT' | 'BOTTOM_CENTER';
    };
}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [visibleCards, setVisibleCards] = useState(3);
    const [isHovered, setIsHovered] = useState(false);
    const autoScrollIntervalRef = useRef<number | null>(null);
    const [enoughCardsForCarousel, setEnoughCardsForCarousel] = useState(true);
    const [stopAutoScroll, setStopAutoScroll] = useState(false);
    const childrenArray = parse(children.props.value) as React.ReactNode[];
    const totalItems = childrenArray.length;
    // Adding touch functionality for mobile swipe
    const touchStartRef = useRef<number | null>(null);
    const touchEndRef = useRef<number | null>(null);
    // For Infinite Scroll
    const [isTransitioning, setIsTransitioning] = useState(false); // New state to handle transition
    // Duplicate the first few elements for smooth infinite scrolling
    const [extendedChildrenArray, setExtendedChildrenArray] = useState<React.ReactNode[]>(
        infiniteScroll ? [...childrenArray.slice(-visibleCards), ...childrenArray, ...childrenArray.slice(0, 3)] : [...childrenArray],
    );

    const buttonClasses = `inline-flex size-8 items-center justify-center rounded border px-1.5 py-2 ${theme === 'light' ? 'border-neutral-300 bg-[#efefef] text-primary-400' : 'border-neutral-400 text-primary-300'}`;

    const updateVisibleCards = useCallback(() => {
        if (typeof window !== 'undefined') {
            let newVisibleCards = 3;
            if (window.innerWidth >= 1280) {
                newVisibleCards = dense ? 6 : 3;
            } else if (window.innerWidth >= 640) {
                newVisibleCards = dense ? 4 : 2;
            } else {
                newVisibleCards = dense ? 2 : 1;
            }
            setVisibleCards(newVisibleCards);
            if (infiniteScroll) {
                setExtendedChildrenArray([
                    ...childrenArray.slice(-newVisibleCards),
                    ...childrenArray,
                    ...childrenArray.slice(0, newVisibleCards),
                ]);
            }
        }
    }, [dense, childrenArray, infiniteScroll]);

    useEffect(() => {
        updateVisibleCards();
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', updateVisibleCards);
            return () => window.removeEventListener('resize', updateVisibleCards);
        }
        return () => window.removeEventListener('resize', updateVisibleCards);
    }, []);

    useEffect(() => {
        setEnoughCardsForCarousel(totalItems > visibleCards);
        if (totalItems <= visibleCards) {
            setCurrentIndex(0);
        }
    }, [visibleCards, totalItems]);

    const scrollPrev = useCallback(() => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? totalItems - visibleCards : prevIndex - 1));
    }, [visibleCards, totalItems]);

    const scrollNext = useCallback(() => {
        setIsTransitioning(true);
        setCurrentIndex((prevIndex) => {
            if (infiniteScroll) {
                return prevIndex === totalItems ? visibleCards : prevIndex + 1;
            } else {
                return prevIndex === totalItems - visibleCards ? 0 : prevIndex + 1;
            }
        });
    }, [visibleCards, totalItems]);

    // For infinite scroll handle smooth reset after reaching cloned items
    useEffect(() => {
        if (infiniteScroll && currentIndex === totalItems) {
            setTimeout(() => {
                setIsTransitioning(false);
                setCurrentIndex(0); // Reset to the first real slide without transition
            }, 300); // Match transition duration
        }
    }, [currentIndex, totalItems, infiniteScroll]);

    // useEffect(() => {
    //     if (!isHovered && enoughCardsForCarousel && !stopAutoScroll) {
    //         if (autoScrollInterval !== null) {
    //             autoScrollIntervalRef.current = setInterval(() => {
    //                 scrollNext();
    //             }, autoScrollInterval); // Auto-scroll every 3 seconds
    //         }
    //     }
    //     return () => {
    //         if (autoScrollIntervalRef.current && autoScrollInterval !== null) {
    //             clearInterval(autoScrollIntervalRef.current);
    //         }
    //     };
    // }, [isHovered, scrollNext, enoughCardsForCarousel, autoScrollInterval, stopAutoScroll]);

    useEffect(() => {
        if (window && !isHovered && enoughCardsForCarousel && !stopAutoScroll) {
            if (autoScrollInterval !== null) {
                // Type assertion to indicate that autoScrollIntervalRef.current should be `NodeJS.Timeout` or `number`
                autoScrollIntervalRef.current = window.setInterval(() => {
                    scrollNext();
                }, autoScrollInterval); // Auto-scroll every 3 seconds
            }
        }
        return () => {
            if (window && autoScrollIntervalRef.current !== null) {
                window.clearInterval(autoScrollIntervalRef.current);
            }
        };
    }, [isHovered, scrollNext, enoughCardsForCarousel, autoScrollInterval, stopAutoScroll]);

    const handleTouchStart = (e: React.TouchEvent) => {
        if (!e.targetTouches[0]) return;
        touchStartRef.current = e.targetTouches[0].clientX;
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        if (!e.targetTouches[0]) return;
        touchEndRef.current = e.targetTouches[0].clientX;
    };

    const handleTouchEnd = () => {
        if (touchStartRef.current !== null && touchEndRef.current !== null) {
            const distance = touchStartRef.current - touchEndRef.current;
            const swipeThreshold = 50; // Minimum swipe distance for action
            if (distance > swipeThreshold) {
                scrollNext(); // Swipe left to go to the next card
                setStopAutoScroll(true);
            } else if (distance < -swipeThreshold) {
                scrollPrev(); // Swipe right to go to the previous card
                setStopAutoScroll(true);
            }
        }
        // Reset touch start and end
        touchStartRef.current = null;
        touchEndRef.current = null;
    };

    return (
        <div
            className="relative max-w-full"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            {enoughCardsForCarousel && controls.show === 'VISIBLE' && controls.placement === 'DEFAULT' && (
                <div className="mb-4 hidden w-full items-center justify-start gap-x-4 lg:flex">
                    <button type="button" onClick={scrollPrev} className={buttonClasses}>
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                            className="size-4"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        <span className="sr-only">Previous slide</span>
                    </button>
                    <div className="flex gap-x-1">
                        {Array.from({ length: totalItems + 1 - visibleCards }).map((_, index) => (
                            <div
                                key={index}
                                className={`size-3 rounded-full ${index === currentIndex ? (theme === 'dark' ? 'bg-primary-300' : 'bg-primary-400') : 'bg-neutral-300'}`}
                            />
                        ))}
                    </div>
                    <button type="button" onClick={scrollNext} className={buttonClasses}>
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                            className="size-4"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        <span className="sr-only">Next slide</span>
                    </button>
                </div>
            )}
            <div
                style={{
                    overflowX: showOverflow?.right ? 'visible' : 'hidden',
                    clipPath: showOverflow?.right && !showOverflow?.left ? 'inset(0px -100% 0px 0px)' : 'none',
                }}
            >
                <div
                    className={`flex transition-transform duration-300 ease-in-out ${dense ? '' : '-mx-2'}`}
                    style={{
                        transform: enoughCardsForCarousel ? `translateX(-${currentIndex * (100 / visibleCards)}%)` : 'none',
                        transitionDuration: isTransitioning ? '300ms' : '0ms',
                    }}
                >
                    {extendedChildrenArray.map((child, index) => (
                        <div key={index} className={`flex-shrink-0 p-2 ${dense ? 'w-1/2 sm:w-1/4 xl:w-1/6' : 'w-full sm:w-1/2 xl:w-1/3'}`}>
                            {child}
                        </div>
                    ))}
                </div>
            </div>
            {enoughCardsForCarousel && controls.show === 'VISIBLE' && (
                <div className={`mt-4 flex w-full justify-center gap-x-4 ${controls.placement === 'BOTTOM_CENTER' ? '' : 'lg:hidden'}`}>
                    <button type="button" onClick={scrollPrev} className={buttonClasses}>
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                            className="size-4"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        <span className="sr-only">Previous slide</span>
                    </button>
                    <div className="flex items-center gap-x-1">
                        {Array.from({ length: totalItems + 1 - visibleCards }).map((_, index) => (
                            <div
                                key={index}
                                className={`size-3 rounded-full ${index === currentIndex ? (theme === 'dark' ? 'bg-primary-300' : 'bg-primary-400') : 'bg-neutral-300'}`}
                            />
                        ))}
                    </div>
                    <button type="button" onClick={scrollNext} className={buttonClasses}>
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                            className="size-4"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        <span className="sr-only">Next slide</span>
                    </button>
                </div>
            )}
        </div>
    );
};
export default MultiCardCarousel;
